<template>
  <div class="signingContract">
    <template v-if="orderInfo">
      <template v-if="orderInfo.status === 0">
        <div class="sctp-tc">
          <div style="line-height: 100px;" class="sctp-info sctp-font-20">等待服务商确认订单</div>
          <div class="fz-18">已过{{ elapsedTime }}</div>
          <div class="mg-t10">(24小时服务商未确认自动取消)</div>
          <div class="mg-t10">
            <el-button @click="goPage('/user/service/order')" size="small" type="primary">返回订单
            </el-button>
            <el-button @click="goPage('/')" size="small">返回主页</el-button>
          </div>
        </div>
      </template>
      <template v-else-if="orderInfo.status === 1">
        <template v-if="!isConfirmAmount">
          <div style="line-height: 100px;" class="sctp-info sctp-tc sctp-font-20">确认已经签订合同并托管资金
          </div>
          <div style="width: 500px;margin: 0 auto;">
            <el-form :model="moneyForm" status-icon :rules="moneyFormRules" ref="moneyForm"
                     label-width="120px"
                     size="small">
              <el-form-item label="商定的金额" prop="money">
                <el-input
                  type="phone"
                  placeholder="请输入商定好的金额"
                  :maxlength="12"
                  v-model.number="moneyForm.money"
                  clearable>
                  <div slot="append">
                    元
                  </div>
                </el-input>
                <el-tooltip class="el-input-question" effect="dark" content="点击查看佣金比例"
                            placement="top">
                  <i @click="profitRateDialogConfig.showDialog()" class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  size="small"
                  @click="onSubmitMoneyFormClick('moneyForm')"
                >
                  确认
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </template>
        <template v-else>
          <div class="sctp-card">
            <div class="sctp-card-header">
              <div class="sctp-card-title sctp-pad-tb20">支付托管资金</div>
            </div>
            <div class="sctp-card-body">
              <pay
                @pay="payServiceConfig.onPay"
                :pay-amount="payServiceConfig.amount"
              ></pay>
            </div>
          </div>
        </template>
      </template>
      <template v-else-if="orderInfo.status === 2">
        <div style="padding: 70px 0" class="sctp-tc">
          <i class="el-icon-success"></i>
          <div class="sctp-color-main">
            <div class="sctp-mar-b15">
              <span class="sctp-mar-r5">已成功托管资金</span>
            </div>
            <router-link :to='`/service/buy/confirmService?orderId=${orderId}`'>
              <el-button type="primary">去确认收到服务</el-button>
            </router-link>
          </div>
        </div>
      </template>
    </template>
    <el-dialog
      center
      title="佣金比例"
      :visible.sync="profitRateDialogConfig.showFlag"
    >
      <profit-rate></profit-rate>
    </el-dialog>
  </div>
</template>

<script>
import {service} from "@/apis/index";
import validator from "@/resources/js/async-validator";
import DateUtil from "@/resources/js/dateutil";

export default {
  name: "signingContract",
  components: {
    pay: () => import('@CMP/common/BuyPay'),
    ProfitRate: () => import('@PAGE/common/ProfitRate'),
  },
  data() {
    return {
      elapsedTime: '0秒',
      profitRateDialogConfig: {
        showFlag: false,
        showDialog: () => {
          this.profitRateDialogConfig.showFlag = true;
        },
      },
      orderId: null,
      orderInfo: null,
      isConfirmAmount: false,
      moneyForm: {
        money: null
      },
      moneyFormRules: {
        money: [
          {required: true, message: '售价不能为空', trigger: ['blur', 'change']},
          {validator: validator.validMoney, message: '金额数值不合法', trigger: ['blur', 'change']},
        ]
      },
      payServiceConfig: {
        amount: null,
        onPay: ({payWay}) => {
          service.payHosting({
            orderId: this.orderId,
            amount: this.payServiceConfig.amount,
            userId: this.user.userId,
            payWay: payWay,
          }).then(res => {
            this.orderDetail();
          })
        }
      },
    }
  },
  computed: {},
  methods: {
    onSubmitMoneyFormClick(formName) {
      this.$refs[formName].validate()
      .then(res => {
        this.$confirm('请确认金额数字是否正确', '提示',).then(() => {
          this.payServiceConfig.amount = this.moneyForm.money;
          this.isConfirmAmount = true;
        })
      })
      .catch(() => {
        this.$message.warning('请完善表单')
      })
    },
    orderDetail() {
      service.orderDetail({
        orderId: this.orderId
      }).then(res => {
        let {retdata} = res;
        this.orderInfo = retdata;
        this.timer = setInterval(() => {
          this.elapsedTime = DateUtil.timeStampDuring(new Date() - retdata.createtime);
        }, 1000);
      });
    }
  },
  beforeMount() {
    let {query: {orderId}} = this.$route;
    if (orderId) {
      this.orderId = orderId
      this.orderDetail();
    } else {
      this.goPage('/');
    }
  },
  destroyed() {
    // 清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style scoped lang="scss">
.signingContract {

  ::v-deep {
    .el-form-item {
      .el-input-question {
        position: absolute;
        right: -25px;
        top: 10px;
        cursor: pointer;
      }
    }
  }
}

.el-icon-success {
  font-size: 100px;
  color: #F90;
  margin-bottom: 15px;
}
</style>
